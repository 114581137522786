






















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import DatePicker from '@/components/form/DatePicker.vue';
import { CORRECT_ANNUAL_ACCOUNTING, FETCH_ACCOUNTING } from '../../../store';
import { Accounting, CorrectAnnualAccounting } from '../../../types';
import { ApiResponse } from '@/components/types';
import { MapAccountingToCorrectCommand } from '@/modules/listOfAssets/model';

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: {
    DatePicker
  }
})
export default class CorrectAnnualAccountingDialog extends Vue {
  @Prop({ type: Object }) accounting!: Accounting | null;

  @ListOfAssets.Action(CORRECT_ANNUAL_ACCOUNTING) correctAnnualAccounting!: (asset: CorrectAnnualAccounting) => Promise<ApiResponse>;
  @ListOfAssets.Action(FETCH_ACCOUNTING) fetchAccounting!: ({ listOfAssetsId, id }: { listOfAssetsId: string, id: string }) => Promise<ApiResponse>;

  error: any = null;
  dialog: boolean = false;
  loading: boolean = false;
  value: CorrectAnnualAccounting | null = null;

  file: string = '';

  get download() {
    if (!this.value) {
      return `Rechnungslegung.pdf`;
    }

    return `Rechnungslegung-${this.value.end}.pdf`;
  }

  @Watch('dialog')
  watchDialogClosed(value: boolean) {
    if (value) return;

    setTimeout(() => {
      this.error = null;
      this.file = '';
      this.dialog = false;
      this.loading = false;
      this.value = null;
      this.$emit('update:accounting', null);
      // @ts-ignore
      this.$refs.form.reset();
    }, 200);
  }

  @Watch('accounting')
  watchAsset(accounting: Accounting) {
    if (!accounting) return;

    this.value = MapAccountingToCorrectCommand(accounting);

    setTimeout(() => {
      this.dialog = true;
    }, 200);
  }

  async save() {
    if (!this.value) return;

    this.error = null;

    return this.correctAnnualAccounting(this.value);
  }

  async fetchPDF(accounting: CorrectAnnualAccounting) {
    this.loading = true;

    const { content, error } = await this.fetchAccounting({
      listOfAssetsId: accounting.list_of_assets_id,
      id: accounting.accounting_id
    });

    if (content) {
      this.file = URL.createObjectURL(content);
    }

    if (!error) {
      this.error = error;
    }

    this.loading = false;
  }
}
